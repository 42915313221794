<template>
  <div class="sonContent">
    <div class="SearchBar">
      <div style="display: flex">
        <span style="margin: 6px 10px 0 20px ">工程编号/名称:</span>
        <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.keyWord"></el-input>
        <span style="margin: 6px 10px 0 20px ">第三方施工队：</span>
        <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.constructionTeam"></el-input>
        <el-select
                v-if="hanleBtn==2"
                v-model="searchBox.timeType"
                style="width: 100px; margin: 0 20px"
                placeholder="请选择"
                size="small"
        >
          <el-option
                  v-for="item in dataList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
                v-if="hanleBtn==2"
                size="small"
                v-model="time"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="选择开始日期时间"
                end-placeholder="选择结束日期时间"
        >
        </el-date-picker>
        <el-button
                v-if="hanleBtn==2"
                type="primary"
                class="SearchBtn"
                size="small"
                @click="search"
                style="margin-left: 20px"
        >查询</el-button>
        <el-button
                v-if="hanleBtn==2"
                type="primary"
                class="SearchBtn"
                size="small"
                @click="exportFile"
                style="margin-left: 10px"
        >导出</el-button>
        <div v-if="hanleBtn==1">
          <span style="margin: 6px 10px 0 10px ">是否退料：</span>
          <el-select v-model="searchBox.pcCheckMaterial" style="width:100px" placeholder="请选择" size="small" clearable>
            <el-option
              v-for="item in chargeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <span style="margin: 6px 10px 0 10px ">退料状态：</span>
          <el-select v-model="searchBox.pcCheckDescribeType" style="width:100px;" placeholder="请选择" size="small" clearable>
            <el-option
              v-for="item in tuiliaoList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        style="display: flex; margin-top: 20px"
        v-if="hanleBtn==1"
      >
        <el-select
          v-model="searchBox.timeType"
          style="width: 100px; margin: 0 10px"
          placeholder="请选择"
          size="small"
        >
          <el-option
            v-for="item in dataList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
          size="small"
          v-model="time"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="选择开始日期时间"
          end-placeholder="选择结束日期时间"
        >
        </el-date-picker>
        <el-button
          type="primary"
          class="SearchBtn"
          size="small"
          @click="search"
          style="margin-left: 10px"
          >查询</el-button
        >
      </div>
    </div>

    <!--数据表格-->
    <el-table
        ref="tableData"
        :data="tableData"
        style="width: 100%;margin: 15px 0;"
        border
      
        :height="hanleBtn==1?'calc(100vh - 448px)':'calc(100vh - 394px)'"
        :stripe="true">
      <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
      <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="constructionTeam" label="第三方施工队" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createBy" label="提交人" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createTime" label="提交时间" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==1" prop="pcCheckBy" label="审核人" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==1" prop="pcCheckTime" label="审核时间" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column  v-if="hanleBtn==2" prop="pcAuditBy" label="审核人" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column  v-if="hanleBtn==2" prop="pcAuditTime" label="审核时间" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==2" prop="pcAuditCost" label="成本" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==2" prop="pcAuditProfit" label="利润" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==2" prop="pcAuditFunds" label="项目总工程款" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==2" label="放款情况" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <el-tag v-if="row.pcAuditLoan==1" type="success">放款完成</el-tag>
            <el-tag v-if="row.pcAuditLoan==2" type="warning">放款中</el-tag>
              <el-tag v-if="row.pcAuditLoan==3">待放款</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="hanleBtn==1" prop="pcObtainBy" label="确认人" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==1" prop="pcObtainTime" label="确认时间" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==1" prop="pcCheckMaterial" label="需要退料" align="center" show-overflow-tooltip>

        <template slot-scope="{row}">
          {{row.pcCheckMaterial==1?'是':'否'}}
        </template>
      </el-table-column>
      <el-table-column v-if="hanleBtn==1" prop="pcCheckDescribeType" label="退料状态" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <el-tag v-if="row.pcCheckDescribeType==1">未退料</el-tag>
          <el-tag v-if="row.pcCheckDescribeType==2" type="warning">退料中</el-tag>
          <el-tag v-if="row.pcCheckDescribeType==3" type="success">退料完成</el-tag>
          <el-tag v-if="row.pcCheckDescribeType==4">不需退料</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="left" width="180">
        <template slot-scope="{row}">
          <el-button v-if="hanleBtn==1" @click="showDetil(row)" type="primary" size="small">详情</el-button>
          <el-button v-if="hanleBtn==1&&row.pcCheckDescribeType==2" @click="makeTrue(row,5)" type="success" size="small">完成退料</el-button>
          <el-button v-if="hanleBtn==2" @click="showDetil(row)" type="primary" size="small">详情</el-button>
          <el-button v-if="hanleBtn==3" @click="showDetil(row)" type="primary" size="small">详情</el-button>
          <el-button v-if="hanleBtn==5" @click="showDetil(row)" type="primary" size="small">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageBox">
      <el-pagination
        :current-page="searchBox.current"
        :background="true"
        :page-sizes="[30, 50, 100]"
        :page-size="searchBox.size"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <DetilCost :detilDialog="detilDialog" @closepop="closepop" :project="project" :hanleBtn="hanleBtn"/>
  </div>
</template>

<script>
  import {auditLoggingList,countsSeriesLogging,updateType,auditParticulars,countsSeriesLoggingExcel} from "../../../RequestPort/cost/cost";
  import { exportMethod } from '../../../common/js/exportExcel'
import DetilCost from './detilCost'
export default {
  name: "dataTabel",
  components: { DetilCost },
  props: {
    hanleBtn: {
      type: [Number, String],
      default: null,
    },
  },
  data(){
    return{
      detilDialog:false,
      searchBox:{
        current:1,
        size: 100,
        timeType:1
      },
      time:[],
      project:{},
      total:null,
      tableData:[],
      dataList:[
        {label:'审核时间',value:3},
        {label:'确认时间',value:2},
        {label:'提交时间',value:1},
      ],
      faliaoList: [
        { label: "提交时间", value: 1 },
        { label: "库房审核时间", value: 2 },
      ],
      tuiliaoList:[
        {label:'未退料',value:1},
        {label:'退料中',value:2},
        {label:'退料完成',value:3},
        {label:'不需退料',value:4},
      ],
      chargeList: [
        { label: "是", value: 1 },
        { label: "否", value: 2 },
      ],
    };
  },
  mounted() {
    if(this.time&&this.time!=null){
      this.searchBox.startTime = this.time[0]
      this.searchBox.endTime = this.time[1]
    }else{
      this.searchBox.startTime = ''
      this.searchBox.endTime = ''
    }
    if(this.hanleBtn==1){//数据员处理记录
      this.loadList(this.searchBox)
    }else{
      this.loadListtwo(this.searchBox)
    }
  },
  methods:{
    exportFile(){
      let obj = {
        timeType:this.searchBox.timeType,
        constructionTeam:this.searchBox.constructionTeam,
        keyWord:this.searchBox.keyWord,
        startTime:this.time.length>0?this.time[0]:'',
        endTime:this.time.length>0?this.time[1]:'',
      }
      countsSeriesLoggingExcel(obj).then(res=>{
        exportMethod(res, '工程量计统审核记录')
      })
    },
    makeTrue(row,type){
      this.$confirm(`确认【${row.projectName}】的所有物料已完成进行退料或调拨？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        updateType({completionId:row.completionId,type:type}).then(res=>{
          if(res.code===200){
            this.$message.success(res.msg)
            if(this.hanleBtn==1){
              this.loadList()//仓管
            }else {
              this.loadListtwo()//计通
            }
          }else {
            this.$message.error(res.msg)
          }
        })
      }).catch(()=>{})
    },
    showDetil(row){
      auditParticulars({completionId:row.completionId}).then(res=>{
        this.project = res.data
        this.detilDialog = true
      })
    },
    closepop(){
      this.detilDialog = false
    },
    loadList(){
      auditLoggingList(this.searchBox).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
        this.$forceUpdate()
      })
    },
    loadListtwo(){
      countsSeriesLogging(this.searchBox).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
        this.$forceUpdate()
      })
    },
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      if(this.hanleBtn==1){
        this.loadList()//仓管
      }else {
        this.loadListtwo()//计通
      }
    },
    handleCurrentChange(val){
      this.searchBox.current = val
      if(this.hanleBtn==1){
        this.loadList()//仓管
      }else {
        this.loadListtwo()//计通
      }
    },
    search(){
      if(this.time&&this.time!=null){
        this.searchBox.startTime = this.time[0]
        this.searchBox.endTime = this.time[1]
      }else{
        this.searchBox.startTime = ''
        this.searchBox.endTime = ''
      }
      this.searchBox.current = 1
      if(this.hanleBtn==1){
        this.loadList()//仓管
      }else {
        this.loadListtwo()//计通
      }
    }
  },
};
</script>

<style scoped>
.SearchBar {
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
</style>